<template>
    <v-app>
        <swiper class="swiper" :options="swiperOptions">
            <swiper-slide>
                <img src="/images/main/6.7_01.jpg" alt="" />
            </swiper-slide>
            <swiper-slide>
                <img src="/images/main/6.7_02.jpg" alt="" />
            </swiper-slide>
            <swiper-slide>
                <img src="/images/main/6.7_03.jpg" alt="" />
            </swiper-slide>
            <swiper-slide>
                <img src="/images/main/6.7_04.jpg" alt="" />
            </swiper-slide>
            <swiper-slide>
                <img src="/images/main/6.7_05.jpg" alt="" />
            </swiper-slide>

            <div class="swiper-control" slot="pagination" @click.prevent>
                <swiper-pagination-secondary />
                <swiper-btn-wrap-tertiary>
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <swiper-button-tertiary />
                        </v-col>
                        <v-col cols="auto">
                            <swiper-button-tertiary next />
                        </v-col>
                    </v-row>
                </swiper-btn-wrap-tertiary>
            </div>
        </swiper>

        <v-bottom-navigation grow>
            <information-dialog>
                <template #activator="{ attrs, on }">
                    <v-btn tile v-bind="attrs" v-on="on">
                        <span class="pt-4px">앱 이용 안내</span>
                        <v-icon>info</v-icon>
                    </v-btn>
                </template>
            </information-dialog>

            <contact-dialog>
                <template #activator="{ attrs, on }">
                    <v-btn tile v-bind="attrs" v-on="on">
                        <span class="pt-4px">연락처</span>
                        <v-icon>contact_support</v-icon>
                    </v-btn>
                </template>
            </contact-dialog>

            <v-btn tile href="https://play.google.com/store/apps/details?id=com.stepcount.step_step_tracking&pli=1" target="_blank">
                <span class="pt-4px">앱 다운로드</span>
                <v-icon>file_download</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-app>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperPaginationSecondary from "@/components/publish/parents/swipers/swiper-pagination-secondary.vue";
import SwiperButtonTertiary from "@/components/publish/parents/swipers/swiper-button-tertiary.vue";

import InformationDialog from "@/components/client/information-dialog.vue";
import ContactDialog from "@/components/client/contact-dialog.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        SwiperPaginationSecondary,
        SwiperButtonTertiary,

        InformationDialog,
        ContactDialog,
    },
    props: {},
    data() {
        return {
            swiperOptions: {
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                // autoplay: {
                //     delay: 5000,
                //     disableOnInteraction: false,
                // },
                speed: 500,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-application {
    max-height: 100vh;
    position: relative;
    background-color: #e1f9f1;

    .swiper-container {
        max-width: 100%;
        max-height: 100vh;
        position: relative;
        img {
            display: block;
            margin: 0 auto;
            max-height: 100vh;
        }
        .swiper-pagination {
            top: 40px;
            bottom: initial;
        }
    }
}
.v-card {
    position: fixed;
    bottom: 68px;
    left: var(--grid-gutter);
    width: fit-content;
    max-width: calc(100% - var(--grid-gutter) * 2);
    z-index: 999;
}
.v-bottom-navigation {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) !important;
    z-index: 999;
    width: 100%;
    display: flex;
    align-items: center;
    .v-btn {
        height: 100% !important;
    }
}
</style>