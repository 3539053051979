var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/main/6.7_01.jpg",
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/main/6.7_02.jpg",
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/main/6.7_03.jpg",
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/main/6.7_04.jpg",
      "alt": ""
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/main/6.7_05.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('swiper-pagination-secondary'), _c('swiper-btn-wrap-tertiary', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-tertiary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-tertiary', {
    attrs: {
      "next": ""
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-bottom-navigation', {
    attrs: {
      "grow": ""
    }
  }, [_c('information-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "tile": ""
          }
        }, 'v-btn', attrs, false), on), [_c('span', {
          staticClass: "pt-4px"
        }, [_vm._v("앱 이용 안내")]), _c('v-icon', [_vm._v("info")])], 1)];
      }
    }])
  }), _c('contact-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "tile": ""
          }
        }, 'v-btn', attrs, false), on), [_c('span', {
          staticClass: "pt-4px"
        }, [_vm._v("연락처")]), _c('v-icon', [_vm._v("contact_support")])], 1)];
      }
    }])
  }), _c('v-btn', {
    attrs: {
      "tile": "",
      "href": "https://play.google.com/store/apps/details?id=com.stepcount.step_step_tracking&pli=1",
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "pt-4px"
  }, [_vm._v("앱 다운로드")]), _c('v-icon', [_vm._v("file_download")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }