<template>
    <v-dialog v-model="shows">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <v-card elevation="4" tile>
            <v-card-title> 연락처 </v-card-title>
            <v-card-text>
                <p>대표번호 : 02-860-2599,2370</p>
                <p>이메일 : gurowalking2023@gmail.com</p>
            </v-card-text>
        </v-card>

        <v-btn large icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-dialog>
</template>
<script>
export default {
    data: () => ({
        shows: false,
    }),
    methods: {
        closeDialog() {
            this.shows = !this.shows;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep() {
    .v-dialog {
        position: relative;
        max-width: calc(100% - var(--container-gutter) * 2) !important;
        background: #fff;
    }
    .v-card {
        padding: 16px 0;
        &__title,
        &__text {
            font-size: var(--txt-font-size) !important;
        }
    }
}
.v-btn {
    position: absolute;
    top: 0;
    right: 0;
}
</style>