var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/information/information.jpg"
    }
  }), _c('v-btn', {
    attrs: {
      "large": "",
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }