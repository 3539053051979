<template>
    <svg
        v-if="direction == 'up' || direction == 'down'"
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 9"
        class="icon-svg icon-chevron"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path d="M0,8.08l.91,.92L8,1.84l7.09,7.16,.92-.92L8,0,0,8.08Z" />
    </svg>
    <svg
        v-else
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 16"
        class="icon-svg icon-chevron"
        :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path d="M8.08341 16L9 15.0924L1.8419 8.00432L9 0.916262L8.08342 8.73544e-07L6.99004e-07 8.00432L8.08341 16Z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 6px;
            }
            &-x-small {
                width: 7px;
            }
            &-small {
                width: 8px;
            }
            &-default {
                width: 9px;
            }
            &-large {
                width: 10px;
            }
            &-x-large {
                width: 12px;
            }
            &-xx-large {
                width: 13px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 6px;
            }
            &-x-small {
                height: 7px;
            }
            &-small {
                height: 8px;
            }
            &-default {
                height: 9px;
            }
            &-large {
                height: 10px;
            }
            &-x-large {
                height: 12px;
            }
            &-xx-large {
                height: 13px;
            }
        }
    }
}
</style>
