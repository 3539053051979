<template>
    <v-dialog v-model="shows">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <v-img src="/images/information/information.jpg"></v-img>

        <v-btn large icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-dialog>
</template>
<script>
export default {
    data: () => ({
        shows: false,
    }),
    methods: {
        closeDialog() {
            this.shows = !this.shows;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep() {
    .v-dialog {
        position: relative;
        max-width: calc(100% - var(--container-gutter) * 2) !important;
    }
}
.v-btn {
    position: fixed;
    top: 44px;
    right: 16px;
}
</style>